import { Modal } from "react-bootstrap";
import { popCancelBttn, PlayButton, Speaker,RedStop,RecordingIcon, PlusIconBlue} from "../../assets";
import { useEffect, useState } from "react";
import ApiServices from "../../utils/apiServices";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Box from '@mui/material/Box';

import Button from "../Button";
const AddLexiModal = (props) =>{
    const [allLexiconFile, setAllLexiconFile ] = useState([]);
    const [allLexiconFileName, setAllLexiconFileName] = useState([]);
    const [selectedLexi, setSelectedLexi] = useState();
    const [selectedLexiconName , setSelectedLexiconName ] = useState('')
    useEffect (()=>{
        FetchLexiconData()
    },[])
    const FetchLexiconData = async() =>{
        let service = new ApiServices();
        let res = await service.getLexiconFile();
        console.log(res)
        setAllLexiconFile(res);
        let allName =[];
          res.forEach(ele => {
            console.log(ele.name)
            allName = allName.concat([`${ele.name}`])

        });
        setAllLexiconFileName(allName)
    } 
    const handleChangeLexicon = (event) =>{
        console.log(event.target.value);
        let filter =allLexiconFile.filter((val)=>{
            return val.name === event.target.value
        }) 
        setSelectedLexi(filter[0])
        setSelectedLexiconName(filter[0]?.name)
        //console.log(filter[0].id)
        //props.setLexiconID(filter[0].id)
        //props.setIsLexicon(true);
    }
    const dispalyLexiconData = () =>{
        return(
            allLexiconFileName.map((val)=>{
                console.log(val)
                return (
                    <MenuItem sx={{ fontSize: "14px",fontWeight: "400",lineHeight:"100%",color:"rgb(50, 49, 48)" }} value={val}>{val}</MenuItem>  
                )
            })
        )
    }
    const handleConfirm = async() => {
        let service = new ApiServices();
        console.log(props.grapheme);
    console.log(props.phoneme)
      let res = await service.updateLexicon(selectedLexi?.id, props.grapheme, props.phoneme)
      if(res){
        props.handleClose();
      }
    }

    return(<>
    <Modal
      show={true}
      size="lg md sm xs"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="popup-transition"
      scrollable={false}
    >
      <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
        <div className="popup-cancel-btn-wrapper">
          <img
            src={popCancelBttn}
            alt=""
            onClick={e => {
              props.handleClose()
            }}
          />
        </div>
      </div>
      <Modal.Header><Modal.Title>Add to lexicon</Modal.Title></Modal.Header>
      <Modal.Body>
      <Box sx={{ width:"250px",marginLeft:'20px' }}>
                                                <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label"
                                                >Select Lexicon File</InputLabel>
                                                    <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={selectedLexiconName}
                                                    label="Select lexicon"
                                                    onChange={handleChangeLexicon}
                                                    sx={{ fontSize: "14px",fontWeight: "400",
                                                            lineHeight:"100%",
                                                            color:"rgb(50, 49, 48)" }}
                                                    >
                                                        {dispalyLexiconData()}
                                                    </Select>
                                                </FormControl>
                                            </Box>
      </Modal.Body>
      <Modal.Footer>
      <Button text="Confirm" customColor='#0074B0' action={() => {handleConfirm()
                            }} />
                            <Button text="Cancel" customColor="#0074B0" action={() => {
                                props.handleClose()
                            }} />
      </Modal.Footer>
    </Modal>
    </>)
}
export default AddLexiModal;