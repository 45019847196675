import { Modal } from "react-bootstrap";
import { popCancelBttn, PlayButton, Speaker,RedStop,RecordingIcon, PlusIconBlue} from "../../assets";
import { useEffect, useState } from "react";
import './AliasModal.css';
import Box from '@mui/material/Box';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Button from "../Button";

const AliasModal = (props) => {
  const [phoneme, setPhoneme] = useState();
  //const [isPhoneme, setIsPhoneme] = useState(true);
  const [isSpeaking, setIsSpeaking] = useState(true);
  const [textselected, setTextselected] = useState('');
  const [refresh , setRefresh] = useState(false);
  const [wordLexicon, setWordLexicon] = useState('');
  
  

  useEffect(()=>{
    setTextselected(props.textselected)
  },[props.textselected])
 /* const timeout = () => {
    const timeout = setTimeout(()=>{
      handleAudioStop();
      SpeechRecognition.stopListening();
    },10000);
  }*/
  
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  useEffect(()=>{
    setPhoneme(transcript)
  },[transcript])

  let timeout;

  /*useEffect(()=>{
     timeout = setTimeout(()=>{
      //SpeechRecognition.stopListening();
      alert("1000ms")
    },10000);
  },[listening])*/

  /*useEffect(()=>{
    setIsMikeOn(false)
  },[(!listening)])*/

console.log(transcript)
const handleConfirm=()=>{
  props.setPhonemeValue(transcript)
  props.setIsPhoneme(true)
  props.handleClose()
}
  return (
    <Modal
      show={true}
      size="lg md sm xs"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="popup-transition"
      scrollable={false}
    >
      <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
        <div className="popup-cancel-btn-wrapper">
          <img
            src={popCancelBttn}
            alt=""
            onClick={e => {
              props.handleClose()
            }}
          />
        </div>
      </div>
      <Modal.Header><Modal.Title>Phoneme</Modal.Title></Modal.Header>
      <Modal.Body>
        <div className="selectedWords">
          {(!props.fromLexicon)?<><label classname="label-1">Selected words</label>
          <div>{textselected}</div></>:<>
          <input type="text" placeholder="Enter the word" value={wordLexicon} onChange={(e)=> setWordLexicon(e.target.valy)}/>
          </>}
        </div>
        <div className="label-1">Phoneme</div>
        <div className="phoneme-text-container">
          <div className="ms-TextField-fieldGroup fieldGroup-563">
            <input type="text" placeholder="Drag or click the phonemes from the library"
              value={phoneme}
              style={{
                width: "33.5rem",
                border: "none"
              }} />
            <button onClick={() => { setPhoneme('') }}><img src={popCancelBttn} /></button>
          </div>
          <div className="phoneme-preview-audio-button audio-button">
            <button
              type="button"
              className="circlebutton"
              onClick={() => { }}
              aria-label="Play"
            >
              <span className="flexContainer-177">
                <img src={PlayButton} />
              </span>
            </button>
          </div>
        </div>
        <div>
          <div style={{ marginTop: "1.25rem"}}>
          <label className={(isSpeaking) ? "label1 underline" : "labe1"}
            style={{ marginLeft: "15px" }}
            onClick={() => {
              setIsSpeaking(true)
            }}>Recognize By Speaking</label>
            </div>
            {(isSpeaking) && 
            <Box className="box-container">
              <button className="spaekerClick"
              style={{marginTop: "0.5rem"}}
              onClick={()=>{
                if(!listening){
                  SpeechRecognition.startListening();
                }
                else if(listening){
                  SpeechRecognition.stopListening();
                }
              }}>{(!listening) ? <img src={Speaker}/>:<img src ={RedStop}/>}</button>
              {(!listening) ? <><div className="Sp-content">Press to start speaking</div>
              <div className="Sp-content">Your recording will be used to generate phonemes and won't be retained.</div></>
                :<>
                <div className="Sp-content"><img src = {RecordingIcon}/></div>
                <div className="Sp-content">You recording time must be between 1s and 10s (inclusive).</div>
                <div className="Sp-content">Recording ... Press button to stop</div></>}
              </Box>}
            </div>
      </Modal.Body>
      <Modal.Footer>
        <button style = {{marginRight:"70px"}}><span style={{marginRight:"5px"}}
        onClick={()=>{props.handleClose()
          props.setAddLexiModal(true)
        }}>
          <img src={PlusIconBlue}/></span>
          <span>Add to lexicon</span></button>
      <Button text="Confirm" customColor='#0074B0' action={() => {
                            handleConfirm()}} />
                            <Button text="Cancel" customColor="#0074B0" action={() => {
                                props.handleClose()
                            }} />
      </Modal.Footer>
    </Modal>
  )
}
export default AliasModal;